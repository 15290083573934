import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class HttpsAuthenticationInterceptor implements HttpInterceptor {
	constructor(private authenticationService: AngularFireAuth) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.authenticationService.idToken.pipe(
			switchMap((token) => {
				let copiedRequest = req.clone({
					setHeaders: {
						'Accept': `application/json`,
						'Content-Type': `application/json`,
					},
				});

				if (token) {
					copiedRequest = req.clone({
						setHeaders: {
							'Accept': `application/json`,
							'Content-Type': `application/json`,
							'Authorization': 'Bearer ' + token,
						},
					});
				}
				return next.handle(copiedRequest);
			})
		);
	}
}
