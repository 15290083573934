import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction, DocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';

import firebase from 'firebase/app';

import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import * as moment from 'moment/moment';
import { Observable, combineLatest, forkJoin, from, of, throwError } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, concatMap, map, switchMap, take, tap, toArray } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BrandInterfaceWithId } from '../interfaces/brands.interface';
import { DispensaryInterfaceWithId } from '../interfaces/dispensary.interface';
import { PermissionsWithIdInterface } from '../interfaces/permissions.interface';
import { UserDataInterface, UserDataInterfaceWithId } from '../interfaces/userData.interface';
import { userDataExportInterface } from '../interfaces/userDataExportReport.interface';
import { chunkArray } from '../utils/array';
import { budtender, buyer, manager } from '../common';
import { activeRoles } from '../common';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private afs: AngularFirestore,
    private http: HttpClient,
    private auth: AngularFireAuth
  ) { }

  /**
   * Get All users from FireStore
   */
  getAllUsers(): Observable<UserDataInterfaceWithId[]> {
    return this.afs
      .collection<UserDataInterface>('userData')
      .snapshotChanges()
      .pipe(
        map((list) =>
          list.map((user) => {
            const data = user.payload.doc.data() as UserDataInterface;
            return {
              ...data,
              id: user.payload.doc.id,
              exists: user.payload.doc.exists,
            };
          })
        )
      );
  }

  getAllUsersFromPermissions(permissions: PermissionsWithIdInterface[]): Observable<UserDataInterfaceWithId[]> {
    const batches$ = [];
    const userDataIds = [...permissions.map((permission) => permission.id)];

    while (userDataIds.length > 0) {
      const batch = userDataIds.splice(0, 10);
      batches$.push(
        of(batch)
          .pipe(
            switchMap(ids => this.afs
              .collection(
                'userData',
                (ref) => {
                  return ref
                    .where(firebase.firestore.FieldPath.documentId(), 'in', ids)
                }
              )
              .snapshotChanges()
            ),
            map((userDataDocs) =>
              userDataDocs.map((userDataDoc) => {
                const data = userDataDoc.payload.doc.data() as UserDataInterface;
                return {
                  ...data,
                  id: userDataDoc.payload.doc.id,
                  exists: userDataDoc.payload.doc.exists,
                } as UserDataInterfaceWithId;
              })
            ),
          )
      );
    }

    return combineLatest(batches$).pipe(
      map((results: any) => results.flat())
    );
  }

  getUsers(): Observable<UserDataInterfaceWithId[]> {
    return this.afs
      .collection('userData')
      .get()
      .pipe(
        map((users) => {
          const docs = users.docs;
          return docs.map((doc) => {
            const data = doc.data() as UserDataInterface;
            return {
              ...data,
              exists: doc.exists,
              id: doc.id,
            } as UserDataInterfaceWithId;
          }) as UserDataInterfaceWithId[];
        })
      );
  }

  getNonArchivedUsers(): Observable<UserDataInterfaceWithId[]> {
    return this.afs
      .collection('userData', (ref) => ref.where('userState', 'not-in', ['archived']))
      .get()
      .pipe(
        map((users) => {
          const docs = users.docs;
          return docs.map((doc) => {
            const data = doc.data() as UserDataInterface;
            return {
              ...data,
              exists: doc.exists,
              id: doc.id,
            } as UserDataInterfaceWithId;
          }) as UserDataInterfaceWithId[];
        })
      );
  }

  getNonArchivedDemoUsers(): Observable<UserDataInterfaceWithId[]> {
    return this.afs
      .collection('userData', (ref) => ref.where('userState', 'not-in', ['archived']).where('role', '==', 'demo'))
      .get()
      .pipe(
        map((users) => {
          const docs = users.docs;
          return docs.map((doc) => {
            const data = doc.data() as UserDataInterface;
            return {
              ...data,
              exists: doc.exists,
              id: doc.id,
            } as UserDataInterfaceWithId;
          }) as UserDataInterfaceWithId[];
        }),
        catchError((err) => {
          console.error(err);
          return of(err);
        })
      );
  }

  getBadgeApprovedUsers(): Observable<UserDataInterfaceWithId[]> {
    return this.afs
      .collection('userData', (ref) => ref.where('badgeVerification.badgeState', '==', 'approved'))
      .get()
      .pipe(
        map((users) => {
          const docs = users.docs;
          return docs.map((doc) => {
            const data = doc.data() as UserDataInterface;
            return {
              ...data,
              exists: doc.exists,
              id: doc.id,
            } as UserDataInterfaceWithId;
          }) as UserDataInterfaceWithId[];
        })
      );
  }

  getAllUsersCount(): Observable<number> {
    return fromPromise(this.afs.firestore.collection('userData').get()).pipe(map((userDocs) => userDocs.size));
  }

  getArchivedUsers(): Observable<UserDataInterfaceWithId[]> {
    return this.afs
      .collection<UserDataInterface>('userData', (ref) => ref.where('userState', '==', 'archived'))
      .snapshotChanges()
      .pipe(
        map((list) =>
          list.map((user) => {
            const data = user.payload.doc.data() as UserDataInterface;
            return {
              ...data,
              id: user.payload.doc.id,
              exists: user.payload.doc.exists,
            };
          })
        )
      );
  }

  getIOSUsers(): Observable<UserDataInterfaceWithId[]> {
    return this.afs
      .collection<UserDataInterface>('userData', (ref) => ref.where('platform', '==', 'ios'))
      .snapshotChanges()
      .pipe(
        map((list) =>
          list.map((user) => {
            const data = user.payload.doc.data() as UserDataInterface;
            return {
              ...data,
              id: user.payload.doc.id,
              exists: user.payload.doc.exists,
            };
          })
        )
      );
  }

  getAndroidUsers(): Observable<UserDataInterfaceWithId[]> {
    return this.afs
      .collection<UserDataInterface>('userData', (ref) => ref.where('platform', '==', 'android'))
      .snapshotChanges()
      .pipe(
        map((list) =>
          list.map((user) => {
            const data = user.payload.doc.data() as UserDataInterface;
            return {
              ...data,
              id: user.payload.doc.id,
              exists: user.payload.doc.exists,
            };
          })
        )
      );
  }

  getInReviewUsers(): Observable<UserDataInterfaceWithId[]> {
    return this.afs
      .collection<UserDataInterface>('userData', (ref) =>
        ref.where('badgeVerification.badgeState', '==', 'inReview').orderBy('createdAt', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map((list) =>
          list.map((user) => {
            const data = user.payload.doc.data() as UserDataInterface;
            return {
              ...data,
              id: user.payload.doc.id,
              exists: user.payload.doc.exists,
            };
          })
        ),
        catchError((err) => {
          console.error(err);
          return of(err);
        })
      );
  }

  getLeafUsers(): Observable<UserDataInterfaceWithId[]> {
    return this.afs
      .collection<UserDataInterface>('userData', (ref) =>
        ref.where('badgeVerification.badgeState', '==', 'approved').where('role', 'in', [budtender, manager, buyer])
      )
      .snapshotChanges()
      .pipe(
        map((list) =>
          list.map((user) => {
            const data = user.payload.doc.data() as UserDataInterface;
            return {
              ...data,
              id: user.payload.doc.id,
              exists: user.payload.doc.exists,
            };
          })
        )
      );
  }

  /**
   * Get All users from FireStore
   */
  getAllArchivedUsers(): Observable<UserDataInterfaceWithId[]> {
    return this.afs
      .collection('userData', (ref) => ref.where('userState', '==', 'archived'))
      .snapshotChanges()
      .pipe(
        map((list) =>
          list.map((user) => {
            const data = user.payload.doc.data() as UserDataInterface;
            return {
              ...data,
              id: user.payload.doc.id,
              exists: user.payload.doc.exists,
            };
          })
        )
      );
  }

  /**
   * Get All users from FireStore
   */
  getAllReferralUsers() {
    return this.afs
      .collection('userData', (ref) => ref.where('referrerId', '!=', null))
      .snapshotChanges()
      .pipe(
        map((list) =>
          list.map((user) => {
            const data = user.payload.doc.data() as UserDataInterface;
            return {
              ...data,
              id: user.payload.doc.id,
              exists: user.payload.doc.exists,
            };
          })
        )
      );
  }

  getInActive30DayUsers(): Observable<UserDataInterfaceWithId[]> {
    let activeUserIds = [];
    const inActiveUsers: UserDataInterfaceWithId[] = [];
    return this.getUsersWith30DayActiveSession().pipe(
      switchMap((userIds) => {
        activeUserIds = userIds;
        return this.afs.collection('userData', (ref) => ref.where('badgeVerification.badgeState', '==', 'approved')).snapshotChanges();
      }),
      map((list) =>
        list.map((userDoc) => {
          const user = userDoc.payload.doc.data() as UserDataInterface;
          if (userDoc.payload.doc.id && activeUserIds.indexOf(userDoc.payload.doc.id) === -1) {
            inActiveUsers.push({
              ...user,
              id: userDoc.payload.doc.id,
              exists: userDoc.payload.doc.exists,
            });
          }
          return userDoc;
        })
      ),
      map(() => {
        return inActiveUsers;
      })
    );
  }

  getUsersDispensariesByIdsObs(
    ids: string[]
  ): Observable<UserDataInterfaceWithId[]> {
    ids = [...new Set(ids)];

    if (!ids?.length) {
      return of([]);
    }

    const batches = chunkArray(ids, 10);

    const result = from(batches).pipe(
      concatMap(async batch => {
        const usersCol = this.afs.firestore.collection('userData');
        const batchUsers = await usersCol.where('dispensaryId', 'in', batch).get()
        return batchUsers;
      }),
      concatMap(query => query.docs),
      toArray(),
      map(values => {
        return values.map(doc => {
          const data: any = doc.data();
          const id = doc.id;
          return {
            ...data,
            id,
            exists: true,
          } as UserDataInterfaceWithId;
        })
      })
    );

    return result;
  }

  getUsersByDispensaryIds(dispensaryIds: string[]): Observable<UserDataInterfaceWithId[]> {
    return fromPromise(
      new Promise((resolve) => {
        let dispensaryUsers: UserDataInterfaceWithId[] = [];
        this.getAllUsers().subscribe((list) => {
          let index = dispensaryIds.length;
          dispensaryIds.forEach((dispensaryId) => {
            const filterUsers = list.filter((userItem) => {
              if (userItem.dispensaryId === dispensaryId) {
                return userItem;
              }
            });
            if (filterUsers.length) {
              dispensaryUsers = dispensaryUsers.concat(filterUsers);
            }
            index = index - 1;
          });
          if (index === 0) {
            resolve(dispensaryUsers);
          }
        });
      })
    );
  }

  async getDispensaryUsersPromise(dispensary: DispensaryInterfaceWithId): Promise<UserDataInterfaceWithId[]> {
    const list = await this.afs.firestore.collection('userData').where('dispensaryId', '==', dispensary.id).get() as firebase.firestore.QuerySnapshot<UserDataInterface>

    return list.docs.map(user => ({
      ...user.data(),
      id: user.id,
      exists: user.exists,
    }))
  }

  getDispensaryUsers(dispensary: DispensaryInterfaceWithId): Observable<UserDataInterfaceWithId[]> {
    return this.afs
      .collection<UserDataInterface>('userData', (ref) => ref.where('dispensaryId', '==', dispensary.id))
      .snapshotChanges()
      .pipe(
        map((list) =>
          list.map((user) => {
            const data = user.payload.doc.data();
            return {
              ...data,
              id: user.payload.doc.id,
              exists: user.payload.doc.exists,
            };
          })
        )
      );
  }

  getReferralUsers(userId: string): Observable<UserDataInterfaceWithId[]> {
    return this.afs
      .collection('userData', (ref) => ref.where('referrerId', '==', userId))
      .snapshotChanges()
      .pipe(
        map((list) =>
          list.map((user) => {
            const data = user.payload.doc.data() as UserDataInterface;
            return {
              ...data,
              id: user.payload.doc.id,
              exists: user.payload.doc.exists,
            };
          })
        )
      );
  }

  getBrandUsers(brand: BrandInterfaceWithId): Observable<UserDataInterfaceWithId[]> {
    return this.afs
      .collection('userData', (ref) => ref.where('brandData.brandId', '==', brand.id))
      .snapshotChanges()
      .pipe(
        map((list) =>
          list.map((user) => {
            const data = user.payload.doc.data() as UserDataInterface;
            return {
              ...data,
              id: user.payload.doc.id,
              exists: user.payload.doc.exists,
            };
          })
        )
      );
  }

  getApprovedUsersByCreatedAtDateRange(startDate: Date, endDate: Date): Observable<UserDataInterfaceWithId[]> {
    const start = firebase.firestore.Timestamp.fromDate(moment(startDate).hours(0).minutes(0).seconds(0).milliseconds(0).toDate());
    const end = firebase.firestore.Timestamp.fromDate(moment(endDate).hours(23).minutes(59).seconds(59).milliseconds(59).toDate());

    return this.afs
      .collectionGroup('userData', (ref) => {
        return ref.where('badgeVerification.badgeState', '==', 'approved').where('createdAt', '>=', start).where('createdAt', '<=', end);
      })
      .snapshotChanges()
      .pipe(
        map((a) =>
          a.map((item) => {
            const data = item.payload.doc.data() as UserDataInterface;
            const id = item.payload.doc.id;
            const exists = item.payload.doc.exists;
            return { id, exists, ...data } as UserDataInterfaceWithId;
          })
        ),
        catchError((err) => {
          console.error(err);
          return of(err);
        })
      );
  }

  getFirstVerifiedUsersByDateRange(startDate: moment.Moment, endDate: moment.Moment): Observable<UserDataInterfaceWithId[]> {
    const start = firebase.firestore.Timestamp.fromDate(startDate.hours(0).minutes(0).seconds(0).milliseconds(0).toDate());
    const end = firebase.firestore.Timestamp.fromDate(endDate.hours(23).minutes(59).seconds(59).milliseconds(59).toDate());

    return this.afs
      .collectionGroup('userData', (ref) => {
        return ref
          .where('badgeVerification.badgeState', '==', 'approved')
          .where('role', 'in', activeRoles)
          .where('firstVerified', '>=', start)
          .where('firstVerified', '<=', end);
      })
      .snapshotChanges()
      .pipe(
        map((a) =>
          a.map((item) => {
            const data = item.payload.doc.data() as UserDataInterface;
            const id = item.payload.doc.id;
            const exists = item.payload.doc.exists;
            return { id, exists, ...data } as UserDataInterfaceWithId;
          })
        ),
        catchError((err) => {
          console.error(err);
          return of(err);
        })
      );
  }

  getFirstVerifiedUsersByStateAndDateRange(
    stateAbbreviation: string,
    startDate: moment.Moment,
    endDate: moment.Moment
  ): Observable<UserDataInterfaceWithId[]> {
    const start = firebase.firestore.Timestamp.fromDate(startDate.hours(0).minutes(0).seconds(0).milliseconds(0).toDate());
    const end = firebase.firestore.Timestamp.fromDate(endDate.hours(23).minutes(59).seconds(59).milliseconds(59).toDate());

    return this.afs
      .collectionGroup('userData', (ref) => {
        return ref
          .where('badgeVerification.badgeState', '==', 'approved')
          .where('role', 'in', activeRoles)
          .where('usDispensaryState', '==', stateAbbreviation)
          .where('firstVerified', '>=', start)
          .where('firstVerified', '<=', end);
      })
      .snapshotChanges()
      .pipe(
        map((a) =>
          a.map((item) => {
            const data = item.payload.doc.data() as UserDataInterface;
            const id = item.payload.doc.id;
            const exists = item.payload.doc.exists;
            return { id, exists, ...data } as UserDataInterfaceWithId;
          })
        ),
        catchError((err) => {
          console.error(err);
          return of(err);
        })
      );
  }

  /**
   * Get current firebase
   */
  getCurrentUser(): Observable<UserDataInterfaceWithId> {
    return this.auth.user.pipe(
      switchMap((user) => {
        if (user) {
          return this.getFireStoreUser(user.uid);
        } else {
          return of(null);
        }
      })
    );
  }

  /**
   *
   * @param uid
   * @private
   */
  private getFireStoreUser(uid): Observable<UserDataInterfaceWithId> {
    return this.afs
      .collection('userData', (ref) => ref.where('authUid', '==', uid))
      .snapshotChanges()
      .pipe(
        map((res) => {
          if (res && res.length === 1) {
            const userDoc = res[0].payload.doc;
            if (userDoc.exists) {
              const data = userDoc.data() as UserDataInterface;
              return {
                ...data,
                id: userDoc.id,
                exists: true,
              };
            } else {
              throw new Error('FireStore User not found');
            }
          } else {
            throw new Error('FireStore User not found');
          }
        })
      );
  }

  /**
   *
   */
  queryStateChangesUsers() {
    console.log(`RUN USERS QUERY - ${moment().format('HH:mm:ss')}`);
    return this.afs
      .collection('userData')
      .stateChanges()
      .pipe(tap((res) => console.log(res.length)));
  }

  addCmsUser(user: Partial<UserDataInterfaceWithId>): Observable<any> {
    const newUser: Partial<UserDataInterfaceWithId> = {
      badgeVerification: {
        badgeFrontUrl: '',
        badgeBackUrl: '',
        badgeState: '' as any,
      },
      version: ' ',
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      fcmToken: '',
      locationEnabled: 'notGranted',
      payoutReceiver: user.email,
      payoutRecipientType: 'email',
      recipientWallet: 'paypal',
      ...user,
    };

    if (newUser.id !== undefined) {
      delete newUser.id;
    }
    if (newUser.exists !== undefined) {
      delete newUser.exists;
    }

    return this.http.post(`${environment.apiUrl}/addCmsUser`, user).pipe(take(1));
  }

  updateEmailCmsUser(email: string, userId: string): Observable<any> {
    const payload = {
      email,
      userId,
    };
    return this.http.post(`${environment.apiUrl}/updateEmailUser`, payload).pipe(take(1));
  }

  getPasswordResetLinkCmsUser(userId: string): Observable<string> {
    const payload = {
      userId,
    };
    return this.http.post<{ message: string; link: string }>(`${environment.apiUrl}/cmsPasswordResetLink`, payload).pipe(
      take(1),
      map((res) => res.link)
    );
  }

  getDuplicateUsers(userId: string): Observable<{ isDuplicate: boolean; duplicateCount: number; duplicateData: UserDataInterfaceWithId[] }> {
    const payload = {
      userId,
    };
    return this.http
      .post<{ isDuplicate: boolean; duplicateCount: number; duplicateData: UserDataInterfaceWithId[] }>(
        `${environment.apiUrl}/cmsValidateForDuplicate`,
        payload
      )
      .pipe(take(1));
  }

  authenticationLookupEmailCmsUser(email: string): Observable<{
    message: string;
    user: {
      /**
       * The user's `uid`.
       */
      uid: string;
      /**
       * The user's primary email, if set.
       */
      email?: string;
      /**
       * Whether or not the user's primary email is verified.
       */
      emailVerified: boolean;
      /**
       * The user's display name.
       */
      displayName?: string;
      /**
       * The user's primary phone number, if set.
       */
      phoneNumber?: string;
      /**
       * The user's photo URL.
       */
      photoURL?: string;
      /**
       * Whether or not the user is disabled: `true` for disabled; `false` for
       * enabled.
       */
      disabled: boolean;
      /**
       * Additional metadata about the user.
       */
      metadata: any;

      providerData: Array<{
        /**
         * The user identifier for the linked provider.
         */
        uid: string;
        /**
         * The display name for the linked provider.
         */
        displayName: string;
        /**
         * The email for the linked provider.
         */
        email: string;
        /**
         * The phone number for the linked provider.
         */
        phoneNumber: string;
        /**
         * The photo URL for the linked provider.
         */
        photoURL: string;
        /**
         * The linked provider ID (for example, "google.com" for the Google provider).
         */
        providerId: string;
      }>;
    };
  }> {
    const payload = {
      email,
    };

    return this.http.post<any>(`${environment.apiUrl}/cmsAuthenticationLookup`, payload).pipe(take(1));
  }

  sendUserInvitation(userId: string): Observable<any> {
    const payload = {
      userId,
    };
    return this.http.post(`${environment.apiUrl}/sendUserInvitation`, payload).pipe(take(1));
  }

  mergeUserAccounts(survivingAccountUid: string, removableAccountUid: string): Observable<any> {
    const payload = {
      survivingAccountUid,
      removableAccountUid,
    };
    return this.http.post(`${environment.apiUrl}/cmsMergeUserAccounts`, payload).pipe(take(1));
  }

  verifyUserEmail(userId: string): Observable<any> {
    const payload = {
      uid: userId,
    };
    return this.http.post(`${environment.apiUrl}/verifyEmailUser`, payload).pipe(take(1));
  }

  verifyUserPhoneNbr(userId: string): Observable<void> {
    const updateUser: Partial<UserDataInterfaceWithId> = {
      smsVerification: {
        smsCode: 12345,
        smsVerified: true,
        lastSmsSend: firebase.firestore.Timestamp.fromDate(new Date()),
      },
    };

    if (updateUser.id) {
      delete updateUser.id;
    }
    if (updateUser.exists) {
      delete updateUser.exists;
    }

    return fromPromise(this.afs.collection('userData').doc(userId).update(updateUser));
  }

  getUsersWithCompletedOffer(): Observable<UserDataInterfaceWithId[]> {
    return this.http.post<any>(`${environment.apiUrl}/getUsersWithAtLeastOneCompletedOffer`, {}).pipe(
      take(1),
      map((res) => res.users as UserDataInterfaceWithId[])
    );
  }

  getUsersWith30DayActiveSession(): Observable<string[]> {
    return this.http.post<any>(`${environment.apiUrl}/active30DaysUserIds`, {}).pipe(
      take(1),
      map((res) => res.userIds as string[])
    );
  }

  /**
   *
   * @param user
   * @param id
   */
  updateUserWithId(user: Partial<UserDataInterfaceWithId>, id: string): Observable<UserDataInterfaceWithId> {
    // Convert Staff object to a new Staff Object to bypass frozen state of NGRX
    const updateUser = {
      ...user,
    };

    if (updateUser.id) {
      delete updateUser.id;
    }
    if (updateUser.exists) {
      delete updateUser.exists;
    }

    return fromPromise(this.afs.collection('userData').doc(id).update(updateUser)).pipe(
      map(() => {
        const exists = true;
        return { ...user, id, exists } as UserDataInterfaceWithId;
      })
    );
  }

  getUsersById(id: string): Observable<UserDataInterfaceWithId> {
    return this.afs
      .collection('userData')
      .doc(id)
      .snapshotChanges()
      .pipe(
        map((res) => {
          if (res && res.payload.exists) {
            const data = res.payload.data() as UserDataInterface;
            return {
              ...data,
              id: res.payload.id,
              exists: true,
            } as UserDataInterfaceWithId;
          } else {
            throwError(new Error('FireStore User not found'));
          }
        })
      );
  }

  getUsersByIds(userIds: string[]): Observable<UserDataInterfaceWithId[]> {
    userIds = [...new Set(userIds)];

    if (!userIds?.length) {
      return of([]);
    }

    const batches = chunkArray(userIds, 10);

    const result = from(batches).pipe(
      concatMap(batch => {
        const userDataCol = this.afs.firestore.collection('userData');
        return fromPromise(userDataCol.where(firebase.firestore.FieldPath.documentId(), 'in', batch).get())
      }),
      concatMap(query => query.docs),
      toArray(),
      map(values =>
        values.map(doc => {
          const data: any = doc.data();
          const id = doc.id;
          return {
            ...data,
            id,
            exists: true,
          } as UserDataInterfaceWithId;
        }))
    )
    return result;
  }

  getUsersByEmail(email: string): Observable<UserDataInterfaceWithId> {
    return this.afs
      .collection('userData', (ref) => ref.where('email', '==', email))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as UserDataInterface;
            const id = a.payload.doc.id;
            const exists = a.payload.doc.exists;
            return { ...data, id, exists } as UserDataInterfaceWithId;
          })
        ),
        map((all) => all[0])
      );
  }

  checkEmailNotTakenUser(email: string) {
    return this.afs
      .collection('userData', (ref) => ref.where('email', '==', email))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as UserDataInterface;
            const id = a.payload.doc.id;
            const exists = a.payload.doc.exists;
            return { ...data, id, exists } as UserDataInterfaceWithId;
          })
        )
      );
  }

  checkEmailNotTakenUser2(email: string): Observable<boolean> {
    return this.authenticationLookupEmailCmsUser(email).pipe(
      take(1),
      map((res) => {
        if (res.user) {
          return true;
        }
        return false;
      })
    );
  }

  /**
   *
   * @param user
   * @param id
   */
  updateDispensaryNameAtRelatedUsers(dispensaryId: string, dispensaryName: string) {
    const userDispensaryCollection = this.afs.collection('userData', (ref) => ref.where('dispensaryId', '==', dispensaryId));

    return userDispensaryCollection.snapshotChanges().pipe(
      take(1),
      switchMap((changes) => {
        const changesTransactions = [];
        changes.map((a) => {
          const id = a.payload.doc.id;
          if (!environment.production) {
            console.log('Update user: ', id);
          }
          changesTransactions.push(
            fromPromise(this.afs.collection('userData').doc(id).update({ dispensary: dispensaryName })).pipe(take(1))
          );
        });
        if (!environment.production) {
          console.log('Return transactions ', changesTransactions.length);
        }
        if (changesTransactions.length) {
          return forkJoin(changesTransactions);
        } else {
          return of(null);
        }
      })
    );
  }

  updateDispensaryDataAtRelatedUsers(dispensaryId: string, updateObject) {
    const userDispensaryCollection = this.afs.collection('userData', (ref) => ref.where('dispensaryId', '==', dispensaryId));

    return userDispensaryCollection.snapshotChanges().pipe(
      take(1),
      switchMap((changes) => {
        const changesTransactions = [];
        changes.map((a) => {
          const id = a.payload.doc.id;
          if (!environment.production) {
            console.log('Update user: ', id);
          }
          changesTransactions.push(fromPromise(this.afs.collection('userData').doc(id).update(updateObject)).pipe(take(1)));
        });
        if (!environment.production) {
          console.log('Return transactions ', changesTransactions.length);
        }
        if (changesTransactions.length) {
          return forkJoin(changesTransactions);
        } else {
          return of(null);
        }
      })
    );
  }

  updateDispensaryPOSAtRelatedUsers(dispensaryId: string, dispensaryPOSname: string) {
    const userDispensaryCollection = this.afs.collection('userData', (ref) => ref.where('dispensaryId', '==', dispensaryId));

    return userDispensaryCollection.snapshotChanges().pipe(
      take(1),
      switchMap((changes) => {
        const changesTransactions = [];
        changes.map((a) => {
          const id = a.payload.doc.id;
          if (!environment.production) {
            console.log('Update user: ', id);
          }
          changesTransactions.push(
            fromPromise(this.afs.collection('userData').doc(id).update({ dispensaryPOS: dispensaryPOSname })).pipe(take(1))
          );
        });
        if (!environment.production) {
          console.log('Return transactions ', changesTransactions.length);
        }
        if (changesTransactions.length) {
          return forkJoin(changesTransactions);
        } else {
          return of(null);
        }
      })
    );
  }

  getUsersFirstPage(size: number): Observable<firebase.firestore.QuerySnapshot<unknown>> {
    return this.afs.collection('userData', (ref) => ref.orderBy('createdAt', 'desc').limit(size)).get();
  }

  getUsersNextPage(size: number, lastInResponse: DocumentSnapshot<any>): Observable<DocumentChangeAction<unknown>[]> {
    return this.afs.collection('userData', (ref) => ref.orderBy('createdAt', 'desc').startAfter(lastInResponse).limit(size)).snapshotChanges();
  }

  getUsersPreviousPage(size: number, firstInResponse: DocumentSnapshot<any>) {
    return this.afs.collection('userData', (ref) => ref.orderBy('createdAt', 'desc').endBefore(firstInResponse).limit(size)).snapshotChanges();
  }

  getUserWithFirstNameMatch(firstNameQuery: string) {
    return this.afs
      .collection('userData', (ref) =>
        ref
          .orderBy('firstName')
          .where('firstName', '>=', firstNameQuery)
          .where('firstName', '<', firstNameQuery + 'z')
      )
      .snapshotChanges();
  }

  getUserWithLastNameMatch(lastNameQuery: string) {
    return this.afs
      .collection('userData', (ref) =>
        ref
          .orderBy('lastName')
          .where('lastName', '>=', lastNameQuery)
          .where('lastName', '<', lastNameQuery + 'z')
      )
      .snapshotChanges();
  }

  getUserWithDispensaryNameMatch(lastNameQuery: string) {
    return this.afs
      .collection('userData', (ref) =>
        ref
          .orderBy('dispensary')
          .where('dispensary', '>=', lastNameQuery)
          .where('dispensary', '<', lastNameQuery + 'z')
      )
      .snapshotChanges();
  }

  getUserWithEmailMatch(emailQuery: string) {
    return this.afs
      .collection('userData', (ref) =>
        ref
          .orderBy('email')
          .where('email', '>=', emailQuery)
          .where('email', '<', emailQuery + 'z')
      )
      .snapshotChanges();
  }

  getUserWithUidMatch(uid: string) {
    return this.afs.collection('userData', (ref) => ref.where('authUid', '==', uid)).snapshotChanges();
  }

  getUsersByRole(role: string) {
    return this.afs.collection('userData', (ref) => ref.where('role', '==', role)).snapshotChanges();
  }

  getUsersByDispensaryState(state: string) {
    return this.afs.collection('userData', (ref) => ref.where('usDispensaryState', '==', state)).snapshotChanges();
  }

  getUserExportData(): Observable<{ data: userDataExportInterface[]; offers: { offerName: string; offerId: string }[]; total: number }> {
    return this.http.get<any>(`${environment.apiUrl}/Q4andQ1report`).pipe(take(1));
  }

  getUserExportData2(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/cmsgetCustomUserExportData`).pipe(take(1));
  }

  getReviewUsersFirstPage(size: number): Observable<DocumentChangeAction<unknown>[]> {
    return this.afs
      .collection('userData', (ref) => ref.orderBy('createdAt', 'desc').where('badgeVerification.badgeState', '==', 'inReview').limit(size))
      .snapshotChanges()
      .pipe(
        catchError((err) => {
          console.error(err);
          return of(err);
        })
      );
  }

  getReviewUsersPreviousPage(size: number, firstInResponse: DocumentSnapshot<any>) {
    return this.afs
      .collection('userData', (ref) =>
        ref.orderBy('createdAt', 'desc').where('badgeVerification.badgeState', '==', 'inReview').endBefore(firstInResponse).limit(size)
      )
      .snapshotChanges()
      .pipe(
        catchError((err) => {
          console.error(err);
          return of(err);
        })
      );
  }

  getReviewUsersNextPage(size: number, lastInResponse: DocumentSnapshot<any>): Observable<DocumentChangeAction<unknown>[]> {
    return this.afs
      .collection('userData', (ref) =>
        ref.orderBy('createdAt', 'desc').where('badgeVerification.badgeState', '==', 'inReview').startAfter(lastInResponse).limit(size)
      )
      .snapshotChanges();
  }

  getReviewUserWithEmailMatch(emailQuery: string) {
    return this.afs
      .collection('userData', (ref) =>
        ref
          .orderBy('email')
          .where('email', '>=', emailQuery)
          .where('email', '<', emailQuery + 'z')
          .where('badgeVerification.badgeState', '==', 'inReview')
      )
      .snapshotChanges()
      .pipe(
        catchError((err) => {
          console.error(err);
          return of(err);
        })
      );
  }

  getReviewUserWithLastNameMatch(lastNameQuery: string) {
    return this.afs
      .collection('userData', (ref) =>
        ref
          .orderBy('lastName')
          .where('lastName', '>=', lastNameQuery)
          .where('lastName', '<', lastNameQuery + 'z')
          .where('badgeVerification.badgeState', '==', 'inReview')
      )
      .snapshotChanges()
      .pipe(
        catchError((err) => {
          console.error(err);
          return of(err);
        })
      );
  }

  getReviewUserWithFirstNameMatch(firstNameQuery: string) {
    return this.afs
      .collection('userData', (ref) =>
        ref
          .orderBy('firstName')
          .where('firstName', '>=', firstNameQuery)
          .where('firstName', '<', firstNameQuery + 'z')
          .where('badgeVerification.badgeState', '==', 'inReview')
      )
      .snapshotChanges()
      .pipe(
        catchError((err) => {
          console.error(err);
          return of(err);
        })
      );
  }

  getReviewUserWithDispensaryNameMatch(lastNameQuery: string) {
    return this.afs
      .collection('userData', (ref) =>
        ref
          .orderBy('dispensary')
          .where('dispensary', '>=', lastNameQuery)
          .where('dispensary', '<', lastNameQuery + 'z')
          .where('badgeVerification.badgeState', '==', 'inReview')
      )
      .snapshotChanges()
      .pipe(
        catchError((err) => {
          console.error(err);
          return of(err);
        })
      );
  }

  getReviewUserWithUidMatch(uid: string) {
    return this.afs
      .collection('userData', (ref) => ref.where('authUid', '==', uid).where('badgeVerification.badgeState', '==', 'inReview'))
      .snapshotChanges()
      .pipe(
        catchError((err) => {
          console.error(err);
          return of(err);
        })
      );
  }

  getReviewUsersByRole(role: string) {
    return this.afs
      .collection('userData', (ref) => ref.where('role', '==', role).where('badgeVerification.badgeState', '==', 'inReview'))
      .snapshotChanges()
      .pipe(
        catchError((err) => {
          console.error(err);
          return of(err);
        })
      );
  }

  getReviewUsersByDispensaryState(state: string) {
    return this.afs
      .collection('userData', (ref) => ref.where('usDispensaryState', '==', state).where('badgeVerification.badgeState', '==', 'inReview'))
      .snapshotChanges()
      .pipe(
        catchError((err) => {
          console.error(err);
          return of(err);
        })
      );
  }

  getAllInternalUsers(): Observable<UserDataInterfaceWithId[]> {
    return this.afs
      .collection('permissions', (ref) => ref.where('internalAccess', '==', true))
      .snapshotChanges()
      .pipe(
        switchMap((userDocs) =>
          this.getUsersByIds(userDocs.map((userDoc) => userDoc.payload.doc.id)))
      );
  }
}
