import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'leaf-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	title = 'Leafvip CMS';

	constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
		console.log('Version: ', environment.release);
		iconRegistry.addSvgIcon('leaf-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/_img/_icons/leaf.svg'));
		if (!environment.production) {
			this.title = `Leafvip CMS - ${environment.envName}`;
		}
	}
}
