import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_shared/guards/auth.guard';
import { SignOutGuard } from './_shared/guards/sign-out.guard';
import { LoadingComponent } from './components/loading/loading.component';

const routes: Routes = [
	{
		path: 'signout',
		canActivate: [SignOutGuard],
		loadChildren: () => import('./components/login/login.module').then((m) => m.LoginModule),
	},
	{
		path: 'login',
		loadChildren: () => import('./components/login/login.module').then((m) => m.LoginModule),
	},
	{
		path: 'loading',
		canActivate: [AuthGuard],
		component: LoadingComponent,
	},
	{
		path: 'secure',
		canActivate: [AuthGuard],
		loadChildren: () => import('./_secure/secure.module').then((m) => m.SecureModule),
	},
	{
		path: '**',
		redirectTo: '/loading',
		pathMatch: 'full',
	},
	{
		path: '',
		redirectTo: '/loading',
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
