import { Injectable } from '@angular/core';
import * as dispensariesActions from './dispensaries.actions';
import * as dispensariesSelectors from './dispensaries.selectors';
import { DispensariesState } from './dispensaries.reducer';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DispensaryInterfaceWithId } from '../../_shared/interfaces/dispensary.interface';
import { fromPromise } from 'rxjs/internal-compatibility';
import { select, Store } from '@ngrx/store';

@Injectable()
export class DispensariesFacade {
	dispensariesLoaded$ = this.store.pipe(select(dispensariesSelectors.getDispensariesLoaded));
	allDispensaries$ = this.store.pipe(select(dispensariesSelectors.getAllDispensaries));

	selectedDispensaries$ = this.store.pipe(select(dispensariesSelectors.getSelected));
	allDispensariesDictionary$ = this.store.pipe(select(dispensariesSelectors.getDispensariesEntities));

	addDispensariesErrors$ = this.store.pipe(select(dispensariesSelectors.getAddDispensariesError));
	addDispensariesSuccess$ = this.store.pipe(select(dispensariesSelectors.getAddDispensariesSuccess));

	updateDispensariesErrors$ = this.store.pipe(select(dispensariesSelectors.getUpdateDispensariesError));
	updateDispensariesSuccess$ = this.store.pipe(select(dispensariesSelectors.getUpdateDispensariesSuccess));
	dispensariesQueryError$ = this.store.pipe(select(dispensariesSelectors.getAddDispensariesError));

	constructor(private store: Store<DispensariesState>) {}

	loadDispensariesFromFirestore() {
		this.store.dispatch(dispensariesActions.queryFireStoreDispensaries({}));
	}

	addDispensary(dispensaries: DispensaryInterfaceWithId) {
		const newDispensaries = {
			id: null,
			exists: false,
			...dispensaries,
		};
		this.store.dispatch(
			dispensariesActions.addDispensaries({
				dispensaries: newDispensaries,
			})
		);
	}

	updateDispensaries(dispensariesId: string, changes: DispensaryInterfaceWithId) {
		this.store.dispatch(
			dispensariesActions.updateDispensaries({
				id: dispensariesId,
				changes,
			})
		);
	}

	getDispensaryById(id: string): Observable<DispensaryInterfaceWithId> {
		return this.store.select(dispensariesSelectors.getAllDispensaries).pipe(map((list) => list.find((item) => item.id === id)));
	}

	getDispensariesByIds(ids: string[]): Observable<DispensaryInterfaceWithId[]> {
		return fromPromise(
			new Promise((resolve) => {
				const results = [];
				ids.forEach((id) =>
					this.store
						.select(dispensariesSelectors.getAllDispensaries)
						.pipe(map((list) => list.find((item) => item.id === id)))
						.subscribe((res) => results.push(res))
				);
				resolve(results);
			})
		);
	}
}
