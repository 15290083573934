import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatProgressSpinnerModule, MatSpinner } from '@angular/material/progress-spinner';
import { UiSpinnerService } from './ui-spinner.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
	declarations: [],
	imports: [CommonModule, OverlayModule, MatProgressSpinnerModule, MatSnackBarModule],
	providers: [UiSpinnerService],
	entryComponents: [MatSpinner],
})
export class UiSpinnerModule {}
