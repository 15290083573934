import { createAction, props } from '@ngrx/store';
import { DispensaryInterfaceWithId } from '../../_shared/interfaces/dispensary.interface';

export const loadDispensaries = createAction('[Dispensaries] Load Dispensaries');

export const loadDispensariesSuccess = createAction(
	'[Dispensaries] Load Dispensaries Success',
	props<{ dispensaries: DispensaryInterfaceWithId[] }>()
);

export const loadDispensariesFailure = createAction('[Dispensaries] Load Dispensaries Failure', props<{ error: any }>());

// ADD Dispensaries
export const addDispensaries = createAction('[Dispensaries] add dispensaries', props<{ dispensaries: DispensaryInterfaceWithId }>());

export const addDispensariesSuccess = createAction('[Dispensaries] added with success', props<any>());

export const addDispensariesError = createAction('[Dispensaries] added failed', props<{ error: Error }>());

// REMOVE STAFF
export const removeDispensaries = createAction(
	'[Dispensaries] removed',
	props<{
		id: string;
	}>()
);

export const removeDispensariesSuccess = createAction('[Dispensaries] removed with success', props<any>());

export const removeDispensariesError = createAction('[Dispensaries] removed failed', props<{ error: Error }>());

// UPDATE STAFF
export const updateDispensaries = createAction(
	'[Dispensaries] update',
	props<{
		id: string;
		changes: DispensaryInterfaceWithId;
	}>()
);

export const updateDispensariesSuccess = createAction('[Dispensaries] update success', props<any>());

export const updateDispensariesError = createAction('[Dispensaries] update failed', props<{ error: Error }>());

// FIRESTORE ACTIONS

export const queryFireStoreDispensaries = createAction('[Dispensaries] query dispensaries', props<any>());

export const queryFireStoreDispensariesError = createAction('[Dispensaries] error at query dispensaries', props<{ error: Error }>());

export const fromFireStoreAddDispensaries = createAction('[Dispensaries] added by Firestore', props<{ dispensary: DispensaryInterfaceWithId }>());

export const fromFireStoreModifyDispensaries = createAction(
	'[Dispensaries] modified by Firestore',
	props<{ dispensary: DispensaryInterfaceWithId }>()
);
export const fromFireStoreRemoveDispensaries = createAction(
	'[Dispensaries] removed by Firestore',
	props<{
		id: string;
	}>()
);
